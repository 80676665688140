import buildingConstImg from "../assets/1.jpg"
import buildingDesigImg from "../assets/buildingdesign.jpg"
import substationConstImg from "../assets/substationconstruction.jpg"
import substaionDesigImg from "../assets/substationdesign.jpg"
import interiorDesigImg from "../assets/interiordesign.jpg"
import transmissionDesigImg from "../assets/tldesign.jpg"

export const Servicelists = [
    {
        serviceImage: buildingConstImg,
        serviceText: "Professional Building Construction Services Tailored to Your Needs.",
        serviceName: "Building Construction",
        uri: "/services/buildingconstruction"
    },

    {
        serviceImage: buildingDesigImg,
        serviceText: "Expert Building Design Services for Your Dream Projects.",
        serviceName: "Building Design",
        uri: "/services/buildingdesign"
    },

    {
        serviceImage: interiorDesigImg,
        serviceText: "Transform Your Space with Our Expert Interior Design Services.",
        serviceName: "Interior Design",
        uri: "/services/interiordesign"
    },

    {
        serviceImage: substaionDesigImg,
        serviceText: "Comprehensive Substation Design Services up to 400kV.",
        serviceName: "Substation Design",
        uri: "/services/substationdesign"
    },

    {
        serviceImage: substationConstImg,
        serviceText: "Expert Substation Construction Services up to 400kV.",
        serviceName: "Substation Construction",
        uri: "/services/substationconstruction"
    },




    {
        serviceImage: transmissionDesigImg,
        serviceText: "Expert Transmission Line Design Services up to 400kV.",
        serviceName: "Transmission Line Design",
        uri: "/services/tldesign"
    },
]