import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import logo from "../assets/3.jpg"
import ServicesComponent from "../components/ServicesComponent";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero"
                heroImg={logo}
                title="Building Tomorrow with Quality."
                text="Delivering superior craftsmanship with durable, high-quality structures that exceed expectations and stand the test of time."
                url="/contact"
                btnClass = "show"
                btnText = "Contact Us"
            />
            <ServicesComponent/>
            <Footer/>
        </>
    )
}

export default Home;