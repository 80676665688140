import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import serviceImg from "../assets/4.jpg"
import ServicesComponent from "../components/ServicesComponent";
import Footer from "../components/Footer";

const Service = () => {
    return(
        <>
           <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={serviceImg}
                title="Service"
               
                btnClass = "hide"
               
            />
            <ServicesComponent/>
            <Footer/>
        </>
    )
}

export default Service;