import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import aboutImg from "../assets/1.jpg"
import Footer from "../components/Footer";
import "../components/Content.css"

const About = () => {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={aboutImg}
                title="About"

                btnClass="hide"

            />

            <section class="content">
                <p>
                    Welcome to <strong>Imarat Construction</strong>, where innovation, quality, and dedication define who we are. As a leading construction company, we specialize in delivering top-tier services across various sectors, including building design, construction, interior design, and high-voltage electrical infrastructure such as substation and transmission line design and construction up to 400kV. Our comprehensive approach ensures that every project we undertake is completed with precision, efficiency, and attention to detail.
                </p>

                <h3>Who We Are</h3>
                <p>
                    With years of experience in the industry, <strong>Imarat Construction</strong> has grown into a trusted partner for clients who value superior craftsmanship and dependable service. Our team of highly skilled architects, engineers, designers, and project managers works together to bring your vision to life, whether it’s a commercial development, a residential building, or complex power infrastructure.
                </p>

                <h3>Our Mission</h3>
                <p>
                    Our mission is to exceed client expectations by providing high-quality construction and design solutions that are sustainable, innovative, and tailored to the specific needs of each project. We are committed to delivering projects that are not only built to last but also make a positive impact on the communities we serve.
                </p>

                <h3>What We Do</h3>
                <ul>
                    <li><strong>Building Design:</strong> Our architectural design team creates functional, aesthetically pleasing spaces that align with your vision and operational goals.</li>
                    <li><strong>Building Construction:</strong> We bring designs to life through careful planning, quality materials, and expert craftsmanship, ensuring timely project completion with uncompromised quality.</li>
                    <li><strong>Interior Design:</strong> From concept to execution, we design interior spaces that reflect your style, enhance functionality, and elevate the user experience.</li>
                    <li><strong>Substation Design & Construction (up to 400kV):</strong> We provide expert design and construction services for electrical substations, ensuring safe and reliable power distribution for various industries.</li>
                    <li><strong>Transmission Line Design (up to 400kV):</strong> Our specialized transmission line design services ensure the efficient and secure delivery of high-voltage power over long distances.</li>
                </ul>

                <h3>Why Choose Us?</h3>
                <ul>
                    <li><strong>Comprehensive Expertise:</strong> With a broad range of services, we are your one-stop solution for all construction, design, and infrastructure needs.</li>
                    <li><strong>Commitment to Quality:</strong> We are committed to delivering exceptional quality in every aspect of our work, from the materials we use to the finished product.</li>
                    <li><strong>Client-Centered Approach:</strong> We take the time to understand your needs and objectives, ensuring that each project reflects your vision and delivers value.</li>
                    <li><strong>Safety and Compliance:</strong> We adhere to the highest standards of safety and regulatory compliance, ensuring that every project is built safely and meets all necessary codes and regulations.</li>
                    <li><strong>Sustainable Practices:</strong> We incorporate sustainable building practices to minimize environmental impact and promote energy efficiency, helping you build for the future.</li>
                </ul>

                <h3>Our Values</h3>
                <ul>
                    <li><strong>Integrity:</strong> We operate with transparency and honesty, ensuring that our clients can trust us to deliver what we promise.</li>
                    <li><strong>Innovation:</strong> We embrace new technologies and creative approaches to offer solutions that are forward-thinking and meet the evolving demands of the industry.</li>
                    <li><strong>Excellence:</strong> From the design phase to project completion, we strive for excellence in everything we do.</li>
                    <li><strong>Collaboration:</strong> We work closely with clients, partners, and communities to ensure successful outcomes for every project.</li>
                </ul>

                <h3>Our Team</h3>
                <p>
                    At the heart of <strong>Imarat Construction</strong> is our dedicated team of professionals. Our engineers, designers, project managers, and construction experts bring a wealth of experience and expertise to every project, ensuring it is completed on time, on budget, and to the highest standards of quality.
                </p>

                <h3>Contact Us</h3>
                <p>
                    Whether you are planning a new building, upgrading infrastructure, or need expert design services, <strong>Imarat Construction</strong> is here to help. Contact us today to learn more about how we can make your next project a success.
                </p>
            </section>

            <Footer />

        </>
    )
}

export default About;