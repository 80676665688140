import React from 'react'

const Validation = (values) => {
  const errors = {}

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;
  const password_pattern = /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20}$/;

  if (values.name === ""){
    errors.name = "Name is requied!"
  }

  if (values.email === ""){
    errors.email = "Email is requied!"
  } else if(!email_pattern.test(values.email)) {
    errors.email = "Email is not correct!"
  }

  if(values.message === ""){
    errors.message = "Message field is empty! Please wirte your query."
  }

  return errors;

}

export default Validation;
