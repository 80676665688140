import React from 'react'
import "./ServicesComponentStyles.css"
import { Servicelists } from './Servicelists'
import { useNavigate } from 'react-router-dom'

const ServicesComponent = () => {

    const navigate = useNavigate();

   

    return (
        <div className='serviceComponent'>
            <h1>Our Services</h1>
            <div className='serviceContainer'>

                {
                    Servicelists.map((item, index) => {
                        return (
                            <div className='box' key={index} onClick={()=>{ navigate (item.uri)}}>
                                <div className='serviceImg'>
                                    <img alt='service image' src={item.serviceImage} />
                                    <div className='serviceOverlay'>
                                        <p>
                                            {item.serviceText}
                                        </p>
                                    </div>
                                </div>
                                <div className='serviceName'>
                                    <h3>{item.serviceName}</h3>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>

    )
}

export default ServicesComponent;
