import './Content.css';
import Hero from './Hero';
import Navbar from './Navbar';
import substationconstruction from "../assets/substationconstruction.jpg"
import Footer from './Footer';

const SubstationConstructionComponent = () => {
    return(

        <>
        {<Hero
                cName="hero-mid"
                heroImg={substationconstruction}
                title="Expert Substation Construction Services up to 400kV"

                btnClass="hide"
            />
            }

            {<Navbar />}

            <section className="content">
            <p>
                At <strong>Imarat Construciton</strong>, we are proud to offer advanced substation construction services for electrical networks up to 400kV. Our expertise in high-voltage systems and infrastructure development ensures the successful execution of projects that form the backbone of energy distribution. From utilities to industrial facilities, we provide customized solutions to meet the energy needs of today and tomorrow.
            </p>

            <h2>Why Trust Us with Your Substation Construction?</h2>
            <ul>
                <li><strong>Deep Industry Knowledge:</strong> With years of experience in constructing high-voltage substations, our team is equipped to handle the complexities of building substations up to 400kV. We follow industry-leading practices to ensure safety, reliability, and long-term performance.</li>
                <li><strong>Comprehensive Project Management:</strong> We offer a full range of substation construction services, handling everything from initial design to final commissioning. This integrated approach allows us to streamline the process and deliver projects on time and within budget.</li>
                <li><strong>Strict Adherence to Safety Standards:</strong> Safety is non-negotiable in our work. We comply with stringent industry safety regulations and ensure that every aspect of the substation construction is designed and implemented with safety as the top priority.</li>
                <li><strong>Innovative Technology Solutions:</strong> We embrace modern technology, incorporating digital substations and advanced control systems into our projects. These innovations lead to better energy efficiency, system automation, and remote monitoring capabilities for optimized performance.</li>
                <li><strong>Unmatched Quality:</strong> We are committed to delivering substation projects built with high-quality materials and state-of-the-art equipment, ensuring durability and consistent performance over the long term.</li>
            </ul>

            <h2>Our Substation Construction Process</h2>
            <ol>
                <li><strong>Consultation and Planning:</strong> We start by understanding the specific requirements of your project. Our team conducts a comprehensive site evaluation and develops a tailored plan to meet your needs.</li>
                <li><strong>Design and Engineering:</strong> Our experienced engineers create detailed substation designs that consider both current operational demands and future expansion needs.</li>
                <li><strong>Civil and Site Works:</strong> We prepare the site with all necessary groundwork, including excavation, grading, and foundation construction, to set the stage for the electrical infrastructure.</li>
                <li><strong>Electrical Installation:</strong> We expertly install all substation components, including transformers, switchgear, busbars, and control systems, ensuring seamless energy transmission and distribution.</li>
                <li><strong>Testing and Commissioning:</strong> Once construction is complete, we perform exhaustive tests to ensure every system is functioning optimally and meets regulatory standards before commissioning the substation.</li>
                <li><strong>Post-Construction Support:</strong> We provide ongoing maintenance and support services to keep your substation running at peak efficiency, with options for upgrades and repairs as required.</li>
            </ol>

            <h2>Types of Substations We Construct</h2>
            <ul>
                <li><strong>High-Voltage Transmission Substations:</strong> Essential for the transmission of electricity over long distances, our transmission substations are built to ensure grid stability and high-capacity energy flow.</li>
                <li><strong>Distribution Substations:</strong> We construct distribution substations that step down high voltage to levels suitable for end-users, including residential, commercial, and industrial consumers.</li>
                <li><strong>Switching Substations:</strong> Our switching substations enhance grid management by controlling and directing power flow between different transmission lines, improving grid reliability.</li>
                <li><strong>Substations for Renewable Energy Projects:</strong> We support the integration of renewable energy by constructing substations for solar farms, wind turbines, and other sustainable energy projects, ensuring smooth transmission of green energy to the grid.</li>
            </ul>

            <p>
                At <strong>Imarat Construciton</strong>, we are committed to delivering safe, efficient, and reliable substation construction solutions. Whether you're looking to expand an existing network or build a new substation from the ground up, our expertise ensures the successful execution of your project.
            </p>
        </section>

        <Footer/>
        </>

    )
}

export default SubstationConstructionComponent;