import "./FooterStyles.css"

const Footer = () => {
    return (

        <footer className="footer-distributed">

            <div className="footer-left">

                <h3>Imarat <span>Construciton</span></h3>

                <p className="footer-links">
                    <a href="/" className="link-1">Home</a>

                    <a href="/about">About</a>

                    <a href="/services">Services</a>

                    <a href="/contact">Contact</a>
                </p>

                <p className="footer-company-name">Imarat Construction © {new Date().getFullYear()}</p>
            </div>

            <div className="footer-center">

                <div>
                <i className="fa-solid fa-location-dot"></i>
                    <p><span>House - 11, Road - 4, Block - J, Banasree, Dhaka - 1219</span> </p>
                </div>

                <div>
                <i className="fa-solid fa-phone"></i>
                    <p>+8801967988074</p>
                </div>

                <div>
                    <i className="fa fa-envelope"></i>
                    <p><a href="mailto:info@imaratconstruction.com">info@imaratconstruction.com</a></p>
                </div>

            </div>

            <div className="footer-right">

                <p className="footer-company-about">
                    <span>About the company</span>
                    Delivering superior craftsmanship with durable, high-quality structures that exceed expectations and stand the test of time.
                </p>

                <div className="footer-icons">

                    <a href="#"><i className="fa-brands fa-square-facebook"></i></a>
                    <a href="#"><i className="fa-brands fa-x-twitter"></i></a>
                    <a href="#"><i className="fa-brands fa-linkedin"></i></a>

                </div>

            </div>

        </footer>
    )
}

export default Footer;
