import './Content.css';
import Hero from './Hero';
import Navbar from './Navbar';
import tldesign from "../assets/tldesign.jpg"
import Footer from './Footer';

const TlDesignContent = () => {
    return(
        <>
        {<Hero
                cName="hero-mid"
                heroImg={tldesign}
                title="Expert Transmission Line Design Services up to 400kV"

                btnClass="hide"
            />
            }

            {<Navbar />}

            <section class="content">
            <p>
                At <strong>Imarat Construciton</strong>, we provide top-tier transmission line design services for electrical infrastructure up to 400kV. Our team of highly skilled engineers specializes in designing transmission lines that ensure safe, reliable, and efficient power delivery over long distances. From feasibility studies to detailed engineering, we deliver innovative and cost-effective solutions tailored to the specific needs of each project.
            </p>

            <h2>Why Choose Our Transmission Line Design Services?</h2>
            <ul>
                <li><strong>Extensive Expertise:</strong> With years of experience in designing high-voltage transmission lines, our team brings a deep understanding of the complexities involved in projects up to 400kV. We ensure that every design meets the highest standards for efficiency and safety.</li>
                <li><strong>Custom Solutions:</strong> We understand that every project has unique challenges. Our transmission line designs are customized to your site conditions, operational requirements, and budget constraints, ensuring optimal performance and durability.</li>
                <li><strong>Compliance and Safety Focus:</strong> Our designs comply with all relevant industry standards and regulatory requirements. We prioritize safety in every aspect of the design, from line routing and clearances to load-bearing capacity and protection against environmental hazards.</li>
                <li><strong>State-of-the-Art Technology:</strong> We use cutting-edge technology, including advanced modeling software and simulation tools, to create robust transmission line designs. This ensures precise engineering and reliable performance, even in the most demanding environments.</li>
                <li><strong>Cost-Effective Design:</strong> Our team focuses on delivering designs that are both technically sound and cost-effective, minimizing material usage and construction costs while ensuring long-term operational efficiency.</li>
            </ul>

            <h2>Our Transmission Line Design Process</h2>
            <ol>
                <li><strong>Initial Consultation:</strong> We start by discussing your project requirements, including load demands, route preferences, and environmental considerations.</li>
                <li><strong>Feasibility Studies and Site Evaluation:</strong> We conduct thorough feasibility studies to assess potential routes, environmental impacts, and cost estimates, providing a clear roadmap for project execution.</li>
                <li><strong>Preliminary Design:</strong> Our engineers develop initial design concepts, including route alignments, structure types, conductor specifications, and insulation levels.</li>
                <li><strong>Detailed Engineering:</strong> Once the preliminary design is approved, we proceed with detailed engineering that includes structural analysis, foundation design, electrical specifications, and material selection. We ensure that all components meet high-voltage standards and that the design is optimized for durability and efficiency.</li>
                <li><strong>Environmental and Safety Considerations:</strong> We carefully assess environmental impacts and implement necessary safety measures, such as right-of-way clearances, grounding, and lightning protection, to ensure the safety and reliability of the transmission line.</li>
                <li><strong>Design Review and Approval:</strong> We work closely with your team to review the final design, making adjustments as needed to ensure it meets all technical and regulatory requirements.</li>
                <li><strong>Documentation and Support:</strong> We provide comprehensive design documentation, including drawings, material lists, and technical specifications, along with ongoing support throughout the construction and commissioning phases.</li>
            </ol>

            <h2>Types of Transmission Line Designs We Offer</h2>
            <ul>
                <li><strong>Overhead Transmission Line Designs:</strong> Our overhead line designs ensure efficient and reliable power transmission over long distances, with a focus on structural integrity and minimal environmental impact.</li>
                <li><strong>Underground Transmission Line Designs:</strong> For areas where overhead lines are not feasible, we design underground transmission systems that offer the same level of reliability while minimizing visual and environmental disturbances.</li>
                <li><strong>High-Voltage Direct Current (HVDC) Transmission Designs:</strong> We provide HVDC transmission line designs that offer efficient long-distance power transmission with reduced losses, ideal for integrating renewable energy sources into the grid.</li>
                <li><strong>Transmission Lines for Renewable Energy Projects:</strong> We specialize in designing transmission lines that connect renewable energy sources such as solar and wind farms to the grid, ensuring seamless integration of green energy into the power network.</li>
            </ul>

            <p>
                At <strong>Imarat Construciton</strong>, we are committed to delivering high-quality transmission line designs that are safe, efficient, and sustainable. Whether for new installations or upgrades, our expertise ensures the success of your transmission line project from start to finish.
            </p>
        </section>

        <Footer/>
        </>
    )
}

export default TlDesignContent;