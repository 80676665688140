import './Content.css';
import Hero from './Hero';
import Navbar from './Navbar';
import buildingdesign from "../assets/buildingdesign.jpg"
import Footer from './Footer';

const BuildingDesignContent = () => {
    return (
        <>
            {<Hero
                cName="hero-mid"
                heroImg={buildingdesign}
                title="Expert Building Design Services for Your Dream Projects"

                btnClass="hide"
            />
            }

            {<Navbar />}

            <section className="content">
                <p>
                    At <strong>Imarat Construciton</strong>, we believe that great construction begins with visionary design. Our building design services offer a seamless blend of creativity, functionality, and precision, ensuring your project is built to exceed expectations. Whether you’re planning a residential home, commercial facility, or industrial structure, we tailor our designs to meet your specific needs and vision.
                </p>

                <h2>Why Choose Us for Your Building Design?</h2>
                <ul>
                    <li><strong>Tailored Designs:</strong> We work closely with clients to understand their goals, aesthetic preferences, and budget. Our designs are custom-made to reflect your unique vision.</li>
                    <li><strong>Innovative Solutions:</strong> Our team integrates the latest design trends, technologies, and sustainable practices to create modern, efficient spaces that stand the test of time.</li>
                    <li><strong>Attention to Detail:</strong> From initial concept to final blueprint, we leave no stone unturned. Our meticulous attention to detail ensures that every aspect of the design aligns perfectly with your construction goals.</li>
                    <li><strong>Seamless Integration:</strong> Our design process seamlessly blends structural integrity with visual appeal. We ensure that all engineering, safety, and regulatory standards are met without compromising on aesthetics.</li>
                </ul>

                <h2>Our Design Process</h2>
                <ol>
                    <li><strong>Consultation:</strong> We start by discussing your project requirements, vision, and any specific preferences.</li>
                    <li><strong>Conceptual Design:</strong> Our experts create conceptual designs, including sketches and drafts, to give you a visual representation of your future building.</li>
                    <li><strong>Design Refinement:</strong> Based on your feedback, we refine the design to meet your expectations and ensure the best possible outcome.</li>
                    <li><strong>Final Blueprint:</strong> Once approved, we deliver detailed blueprints that serve as the foundation for construction, ensuring a smooth transition from design to build.</li>
                </ol>

                <h2>Types of Projects We Specialize In</h2>
                <ul>
                    <li><strong>Residential Buildings:</strong> From luxury homes to sustainable living spaces, we design with your lifestyle in mind.</li>
                    <li><strong>Commercial Buildings:</strong> Our designs balance form and function, perfect for offices, retail spaces, or mixed-use developments.</li>
                    <li><strong>Industrial Projects:</strong> We ensure our designs for industrial spaces are efficient, durable, and meet operational needs.</li>
                </ul>

                <p>
                    At <strong>Imarat Construciton</strong>, we are committed to delivering building designs that inspire and meet your functional requirements. Let’s create something extraordinary together!
                </p>
            </section>

            <Footer/>
        </>
    )
}

export default BuildingDesignContent;