import React, { useState } from 'react';
import "./ContactComponentStyles.css";
import axios from 'axios';
import Validation from './Validation';


export default function ContactComponent() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState(false);
    const [button, setButton] = useState(true);


    const handleSubmit = (e) => {

        e.preventDefault();



        const data = { name, email, message };

        const validationErrors = Validation(data);

        setErrors(validationErrors);
        setStatus(false);
        setButton(false);
        setLoader(true);



        console.log(data);
        console.log(status);


        if (validationErrors.name || validationErrors.email || validationErrors.message) {
            console.log("Error exists");
            setLoader(false);
            setButton(true);
            return; // Exit if there's an error
        }


        axios.post("https://imaratconstruction-hlhshspf.b4a.run/send_message", data).then((response) => {
            console.log(response);
            if (response.status == 200) {
                document.getElementById("contact").reset();
                setLoader(false);
                setButton(true);
                setStatus(true);
                document.getElementById("status").innerHTML = "Message Sent Successfully.";
                document.getElementById("status").style.color = "green";
                setName("");
                setEmail("");
                setMessage("");
            }
        }).catch((error) => {
            console.log(error);
            setLoader(false);
            setButton(true);
            setStatus(true);
            document.getElementById("status").innerHTML = "Failed to Send the Message.";
            document.getElementById("status").style.color = "red";

        })
    }

    return (

        <div className='contact-container'>
            <section className="contact-us" id="contact-section">
                <form id="contact" method="post" onSubmit={handleSubmit}>

                    <div className="section-heading">
                        <h4>Contact us</h4>
                    </div>
                    <div className="inputField" >
                        <div style={{ display: "flex", gap: "5px", color: "red" }}>
                            <input type="name" name="name" id="name" placeholder="Your name" onInputCapture={(e) => { setName(e.target.value); setStatus(false) }} onFocus={() => errors.name = false} />
                            <span class="req">*</span>
                        </div>
                        {errors.name && <p id='errorname' style={{ color: "red" }}>{errors.name}</p>}
                    </div>

                    <div className="inputField">
                        <div style={{ display: "flex", gap: "5px", color: "red" }}>
                            <input type="Email" name="email" id="email" placeholder="Your email" onInputCapture={(e) => { setEmail(e.target.value); setStatus(false) }} onFocus={() => errors.email = false} />
                            <span class="req">*</span>
                        </div>
                        {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                    </div>

                    <div className="inputField">
                        <div style={{ display: "flex", gap: "5px", color: "red" }}>
                            <textarea name="message" id="message" placeholder="Your message" onInputCapture={(e) => { setMessage(e.target.value); setStatus(false) }} onFocus={() => errors.message = false}></textarea>
                            <span class="req">*</span>
                        </div>
                        {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
                    </div>

                    <div className="inputField btn">
                        <button type="submit" id="form-submit" className="main-gradient-button" style={{ visibility: button ? "visible" : "hidden", transition: 'visibility 1s, opacity 1s', opacity: button ? 1 : 0 }} >Send a message</button>
                    </div>
                    <div id="status" style={{ visibility: status ? "visible" : "hidden" }}></div>
                    <div className="loader" style={{ visibility: loader ? "visible" : "hidden" }}></div>

                </form>
            </section>
            <section className='contact-address'>


                <div className='contact-field'>
                    <i className="fa-solid fa-location-dot"></i>
                    <p>House - 11, Road - 4, Block - J, Banasree, Dhaka - 1219 </p>
                </div>

                <div className='contact-field'>
                    <i className="fa-solid fa-phone"></i>
                    <p>+8801967988074</p>
                </div>

                <div className='contact-field'>
                    <i className="fa fa-envelope"></i>
                    <p><a href="mailto:info@imaratconstruction.com">info@imaratconstruction.com</a></p>
                </div>

            </section>
        </div>
    );
}