import './Content.css';
import Hero from './Hero';
import Navbar from './Navbar';
import substationdesign from "../assets/substationdesign.jpg"
import Footer from './Footer';

const SubstationDesignConent = () => {
    return(

        <>
        {<Hero
                cName="hero-mid"
                heroImg={substationdesign}
                title="Comprehensive Substation Design Services up to 400kV"

                btnClass="hide"
            />
            }

            {<Navbar />}

            <section className="content">
            <p>
                At <strong>Imarat Construciton</strong>, we offer expert substation design services for electrical networks up to 400kV. Our team of skilled engineers and designers specialize in creating efficient, safe, and reliable substation designs that meet the needs of today’s power infrastructure. Whether it's for new construction or upgrading existing systems, we deliver innovative solutions that ensure optimal performance and longevity.
            </p>

            <h2>Why Choose Our Substation Design Services?</h2>
            <ul>
                <li><strong>Specialized Expertise:</strong> Our extensive experience in designing substations up to 400kV means we understand the complexities involved in high-voltage systems. We tailor each design to meet specific project requirements, whether for power transmission, distribution, or renewable energy integration.</li>
                <li><strong>Custom Design Solutions:</strong> We provide bespoke substation designs based on your project's unique challenges, site conditions, and operational needs. Our designs cover everything from layout optimization to the selection of equipment, ensuring seamless integration into your power system.</li>
                <li><strong>Focus on Safety and Compliance:</strong> Safety is at the core of our designs. We ensure that all designs comply with industry standards and regulations, addressing key aspects like grounding, fault protection, and operational safety.</li>
                <li><strong>Cutting-Edge Technology:</strong> We incorporate the latest advancements in substation technology, including digital substations, automation systems, and remote control capabilities. This ensures that our designs are future-proof and equipped for advanced energy management.</li>
                <li><strong>Efficient and Cost-Effective Designs:</strong> Our team balances technical performance with cost efficiency, delivering designs that optimize material use, reduce operational costs, and ensure ease of maintenance.</li>
            </ul>

            <h2>Our Substation Design Process</h2>
            <ol>
                <li><strong>Initial Consultation:</strong> We begin with a thorough consultation to understand your project's goals, site conditions, and operational requirements.</li>
                <li><strong>Feasibility Studies and Site Analysis:</strong> We conduct in-depth studies and site evaluations to determine the best design approach, considering factors like load demands, environmental impact, and future scalability.</li>
                <li><strong>Preliminary Design Development:</strong> Our team develops initial substation layouts, including one-line diagrams, general arrangements, and equipment specifications, ensuring that all aspects are considered.</li>
                <li><strong>Detailed Design:</strong> Once the concept is approved, we create detailed engineering designs that cover civil, structural, and electrical aspects. This includes protection schemes, control systems, and integration of auxiliary systems.</li>
                <li><strong>Design Review and Optimization:</strong> We work closely with your team to review and refine the design, ensuring all project requirements are met and that the final plan aligns with your operational goals.</li>
                <li><strong>Final Documentation and Support:</strong> We provide comprehensive documentation, including technical drawings, bill of materials, and specifications, as well as ongoing support throughout the construction and commissioning phases.</li>
            </ol>

            <h2>Types of Substation Designs We Offer</h2>
            <ul>
                <li><strong>Transmission Substation Designs:</strong> Our designs ensure efficient high-voltage transmission of electricity, with a focus on reliability, safety, and scalability for future network growth.</li>
                <li><strong>Distribution Substation Designs:</strong> We create substation designs that step down voltage levels for safe distribution to residential, commercial, and industrial consumers.</li>
                <li><strong>Switching Substation Designs:</strong> Our switching substation designs are engineered to manage power flow between transmission lines, optimizing grid management and load balancing.</li>
                <li><strong>Renewable Energy Substation Designs:</strong> We specialize in designing substations for renewable energy projects, integrating solar, wind, and other sustainable power sources into the grid.</li>
            </ul>

            <p>
                At <strong>Imarat Construciton</strong>, we are committed to delivering high-quality substation designs that meet the technical, operational, and safety demands of modern power systems. Whether for new installations or upgrades, our design expertise ensures the success of your substation project.
            </p>
        </section>

        <Footer/>
        </>
    )
}

export default SubstationDesignConent;