import "./Content.css"
import Hero from './Hero';
import Navbar from './Navbar';
import buildingconstruciton from "../assets/2.jpg"
import Footer from "./Footer";

const BuildingConstrucitonContent = () => {
    return(
<>
            {<Hero
                cName="hero-mid"
                heroImg={buildingconstruciton}
                title="Professional Building Construction Services Tailored to Your Needs"

                btnClass="hide"
            />
            }

            {<Navbar />}

            <section className="content">
            <p>
                At <strong>Imarat Construciton</strong>, we bring your construction projects to life with precision, efficiency, and expert craftsmanship. Our building construction services cover every stage of the process, from ground-breaking to final completion. Whether it's residential, commercial, or industrial, we are committed to delivering exceptional results that stand the test of time.
            </p>

            <h2>Why Choose Our Building Construction Services?</h2>
            <ul>
                <li><strong>Experienced Team:</strong> With a team of seasoned professionals, we ensure that every construction project is executed to the highest standards, adhering to best practices and local regulations.</li>
                <li><strong>Turnkey Solutions:</strong> We provide end-to-end construction services, managing every aspect of your project. From site preparation and foundation laying to structural work and finishing touches, we handle it all.</li>
                <li><strong>Quality Assurance:</strong> We prioritize using high-quality materials and advanced techniques to ensure that your building is not only aesthetically pleasing but also structurally sound and durable.</li>
                <li><strong>Timely Delivery:</strong> We understand the importance of meeting deadlines. Our team is committed to completing projects on time, without compromising on quality.</li>
                <li><strong>Customized to Your Vision:</strong> Every project is unique. We work closely with our clients to understand their goals and tailor our construction process to meet their specific needs, whether it's a modern residential home or a complex commercial structure.</li>
            </ul>

            <h2>Our Construction Process</h2>
            <ol>
                <li><strong>Initial Consultation:</strong> We begin by discussing your project’s scope, timeline, and budget to ensure we align with your expectations.</li>
                <li><strong>Planning and Design Coordination:</strong> Working in sync with designers or architects, we create a construction plan that is efficient, cost-effective, and meets regulatory standards.</li>
                <li><strong>Site Preparation and Foundation:</strong> We handle all aspects of site preparation, including excavation, grading, and laying a solid foundation for your building.</li>
                <li><strong>Building Construction:</strong> Our team works methodically to erect the structure, focusing on quality, safety, and adherence to the design specifications.</li>
                <li><strong>Finishing and Final Touches:</strong> Once the structure is complete, we take care of the interior and exterior finishing work, ensuring a polished and professional outcome.</li>
                <li><strong>Final Inspection and Handover:</strong> We conduct thorough inspections to ensure everything meets the highest standards before handing over the completed building to you.</li>
            </ol>

            <h2>Our Areas of Expertise</h2>
            <ul>
                <li><strong>Residential Construction:</strong> Whether you’re building your dream home or a multi-family complex, we deliver beautiful, high-quality residential projects that meet your specific needs.</li>
                <li><strong>Commercial Construction:</strong> Our commercial construction services are perfect for offices, retail spaces, restaurants, and other businesses looking for efficient and functional spaces.</li>
                <li><strong>Industrial Construction:</strong> We specialize in constructing durable and efficient industrial buildings, such as warehouses and manufacturing facilities, designed to optimize operations.</li>
            </ul>

            <p>
                At <strong>Imarat Construciton</strong>, we are dedicated to providing reliable, top-quality construction services. Our goal is to build lasting structures that not only fulfill your vision but also meet the highest standards of quality and safety.
            </p>
        </section>
        <Footer/>
        </>
    )
}

export default BuildingConstrucitonContent;