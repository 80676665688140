import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar'
import { Route, Routes } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Service from './routes/Service';
import Contact from './routes/Contact';
import BuildingDesignContent from './components/BuildingDesignContent';
import BuildingConstrucitonContent from './components/BuildingConstructionContent';
import InteriroContent from './components/InteriorContent';
import SubstationDesignConent from './components/SubstationDesignContent';
import SubstationConstructionComponent from './components/SubstationConstructionComponent';
import TlDesignContent from './components/TlDesignContent';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Service />} />
        <Route path='/services/buildingdesign' element={<BuildingDesignContent />} />
        <Route path='/services/buildingconstruction' element={<BuildingConstrucitonContent />} />
        <Route path='/services/interiordesign' element={<InteriroContent />} />
        <Route path='/services/substationdesign' element={<SubstationDesignConent />} />
        <Route path='/services/substationconstruction' element={<SubstationConstructionComponent />} />
        <Route path='/services/tldesign' element={<TlDesignContent />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>



    </div>
  );
}

export default App;
