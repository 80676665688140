
import { Component } from 'react'
import { useState } from "react";
import './NavbarStyles.css'
import { MenuItems } from './MenuItems'
import { Link, useNavigate } from "react-router-dom"

const Navbar =()=> {

    const navigate = useNavigate();

    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
        
    }

    //state = {clicked : false}
        return (
            <nav className='NavbarItems'>
                <h1 className='navbar-logo' onClick={()=>{navigate('/')}}>Imarat Construction</h1>
                <div className='menu-icons' onClick={handleClick}><i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i></div>
                <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link className={item.cName} to={item.url}><i className={item.icon}></i>{item.title}</Link>
                            </li>
                        )
                    })}

                  

                </ul>
            </nav>
        )
    }

export default Navbar;