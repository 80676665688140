import './Content.css';
import Hero from './Hero';
import Navbar from './Navbar';
import interiordesign from "../assets/interiordesign.jpg"
import Footer from './Footer';

const InteriroContent = () => {
    return(
        <>
        {<Hero
            cName="hero-mid"
            heroImg={interiordesign}
            title="Transform Your Space with Our Expert Interior Design Services"

            btnClass="hide"
        />
        }

        {<Navbar />}

        <section className="content">
            <p>
                At <strong>Imarat Construciton</strong>, we specialize in creating beautiful, functional interiors that reflect your unique style and needs. Whether you’re looking to revamp your home, office, or commercial space, our interior design services are tailored to bring your vision to life. From concept development to the final touches, we ensure every element of your space is carefully planned and flawlessly executed.
            </p>

            <h2>Why Choose Us for Your Interior Design?</h2>
            <ul>
                <li><strong>Personalized Designs:</strong> We take the time to understand your tastes, lifestyle, and specific goals to create a design that feels authentically you.</li>
                <li><strong>Comprehensive Solutions:</strong> From furniture selection to lighting, color schemes, and spatial planning, we manage every detail to create cohesive and harmonious spaces.</li>
                <li><strong>Functional and Aesthetic Balance:</strong> Our designs not only look great but are also highly functional, ensuring your space is both comfortable and practical for everyday use.</li>
                <li><strong>Expert Craftsmanship:</strong> As part of our construction expertise, we ensure that the design is flawlessly integrated into the structure, whether it involves custom-built elements, layout modifications, or finishing touches.</li>
                <li><strong>Attention to Detail:</strong> We focus on every aspect of interior design, from material selection to textures and finishes, to ensure a polished and professional result.</li>
            </ul>

            <h2>Our Interior Design Process</h2>
            <ol>
                <li><strong>Consultation:</strong> We begin with an in-depth consultation to discuss your ideas, preferences, and budget. This allows us to tailor the design to your specific needs.</li>
                <li><strong>Concept Development:</strong> Based on your input, we develop a design concept that includes layouts, color schemes, materials, and furniture selections. We provide visual aids like mood boards or 3D renderings to help you envision the final look.</li>
                <li><strong>Design Refinement:</strong> We work closely with you to refine the design, making adjustments as needed to ensure it aligns perfectly with your vision.</li>
                <li><strong>Implementation:</strong> Our construction team works in tandem with the design plan to bring your space to life. From renovations to new installations, we ensure the highest quality standards throughout the process.</li>
                <li><strong>Final Touches:</strong> Once construction is complete, we handle all the final details, including decor, art placement, and other finishing touches that make the space uniquely yours.</li>
            </ol>

            <h2>Types of Spaces We Design</h2>
            <ul>
                <li><strong>Residential Interiors:</strong> Whether you want to redesign a single room or your entire home, we create spaces that enhance comfort and style.</li>
                <li><strong>Commercial Interiors:</strong> We design office spaces, retail stores, restaurants, and more, focusing on creating environments that are not only visually appealing but also enhance productivity and customer experience.</li>
                <li><strong>Custom Spaces:</strong> For those with specific requirements or unique spaces, we provide custom design solutions that cater to your individual needs and challenges.</li>
            </ul>

            <p>
                At <strong>Imarat Construciton</strong>, our goal is to transform your interiors into spaces that inspire and impress. Let us help you turn your design dreams into reality with our expert interior design services.
            </p>
        </section>

        <Footer/>
    </>
    )
}

export default InteriroContent;