import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import contactImg from "../assets/2.jpg"
import ContactComponent from "../components/ContactComponent";
import Footer from "../components/Footer";

const Contact = () => {
    return(
        <>
           <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={contactImg}
                title="Contact"
               
                btnClass = "hide"
               
            />

            <ContactComponent/>
            <Footer/>
        </>
    )
}

export default Contact;